<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Redirect",
  created() {
    this.ADD_ORDER_FROM_ROUTE(this.$route.query.order);
    this.$router.push("/");
  },
  methods: {
    ...mapActions(["ADD_ORDER_FROM_ROUTE"])
  }
};
</script>
